

// Import Modules
const dashboardModule = () => import('@/modules/Dashboard/index.vue')
const webpages = ()=> import('@/modules/WebPages/index.vue')
const blog = ()=> import('@/modules/Blogs/index.vue')
const category = ()=>import('@/modules/Category/index.vue')
const users = ()=>import('@/modules/Users/index.vue')
const sites = ()=>import('@/modules/Sites/index.vue')

// Export
export default [
    // Dashboard
    {
        path: '/admin/dashboard',
        component: dashboardModule,
        // meta: { auth: true },
        name: 'admin.dashboard'
    },
    {
        path: '/admin/blogs',
        component: blog,
        // meta: { auth: true },
        name: 'admin.blog'
    },
    {
        path: '/admin/web-pages',
        component: webpages,
        // meta: { auth: true },
        name: 'admin.webpages'
    },
    {
        path: '/admin/category',
        component: category,
        // meta: { auth: true },
        name: 'admin.category'
    },
    {
        path: '/admin/users',
        component: users,
        // meta: { auth: true },
        name: 'admin.users'
    },
    {
        path: '/admin/sites',
        component: sites,
        // meta: { auth: true },
        name: 'admin.sites'
    },
    { path: '*', redirect: '/' }
]